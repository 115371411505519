import dynamic from 'next/dynamic';

const ErrorPage = dynamic(() => import("../components/Main/ErrorPage"));

const NotFound = () => {

  return (
    <ErrorPage />
  )
}

export default NotFound